/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDBeOclnm89SWfwenurcYEcu0EPIzpnjec",
  "appId": "1:50471121512:web:1a0aa7c899d88c3d5a81dd",
  "authDomain": "schooldog-i-tift-ga.firebaseapp.com",
  "measurementId": "G-XXKX5Y57ZP",
  "messagingSenderId": "50471121512",
  "project": "schooldog-i-tift-ga",
  "projectId": "schooldog-i-tift-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-tift-ga.appspot.com"
}
